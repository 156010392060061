import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	output,
} from '@angular/core';
import { IMenuItem } from '../navigation.interface';
import { RippleService } from '@app/_services';
import { CommonModule } from '@angular/common';
import { AppPipes } from '@app/_pipes';
import { IconComponent } from '@app/components/icon/icon.component';

@Component({
	selector: 'navigation-link',
	standalone: true,
	imports: [CommonModule, AppPipes, IconComponent],
	templateUrl: './navigation-link.component.html',
	styleUrl: './navigation-link.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationLinkComponent {
	public item = input<IMenuItem>();
	public type = input('item');
	public nestingLevel = input(0);
	public expanded = input(false);
	public onClickItem = output<IMenuItem>();

	private ripple = inject(RippleService);

	public animateRipple(event: any) {
		this.ripple.animate(event, 'dark');
	}

	public active = computed(() => {
		const item = this.item();
		const expanded = this.expanded();
		return (!item.relative && item.below?.length && expanded) || item.active;
	});
}
