/* tslint:disable */
 export const icons = [
  {
    "name": "activity",
    "fileName": "activity.svg"
  },
  {
    "name": "airplay",
    "fileName": "airplay.svg"
  },
  {
    "name": "alert-circle",
    "fileName": "alert-circle.svg"
  },
  {
    "name": "alert-octagon",
    "fileName": "alert-octagon.svg"
  },
  {
    "name": "alert-triangle",
    "fileName": "alert-triangle.svg"
  },
  {
    "name": "align-center",
    "fileName": "align-center.svg"
  },
  {
    "name": "align-justify",
    "fileName": "align-justify.svg"
  },
  {
    "name": "align-left",
    "fileName": "align-left.svg"
  },
  {
    "name": "align-right",
    "fileName": "align-right.svg"
  },
  {
    "name": "anchor",
    "fileName": "anchor.svg"
  },
  {
    "name": "aperture",
    "fileName": "aperture.svg"
  },
  {
    "name": "apps",
    "fileName": "apps.svg"
  },
  {
    "name": "archive",
    "fileName": "archive.svg"
  },
  {
    "name": "arrow-diagonal",
    "fileName": "arrow-diagonal.svg"
  },
  {
    "name": "arrow-down-circle",
    "fileName": "arrow-down-circle.svg"
  },
  {
    "name": "arrow-down-left",
    "fileName": "arrow-down-left.svg"
  },
  {
    "name": "arrow-down-right",
    "fileName": "arrow-down-right.svg"
  },
  {
    "name": "arrow-down",
    "fileName": "arrow-down.svg"
  },
  {
    "name": "arrow-left-circle",
    "fileName": "arrow-left-circle.svg"
  },
  {
    "name": "arrow-left",
    "fileName": "arrow-left.svg"
  },
  {
    "name": "arrow-orange",
    "fileName": "arrow-orange.svg"
  },
  {
    "name": "arrow-red",
    "fileName": "arrow-red.svg"
  },
  {
    "name": "arrow-right-circle",
    "fileName": "arrow-right-circle.svg"
  },
  {
    "name": "arrow-right",
    "fileName": "arrow-right.svg"
  },
  {
    "name": "arrow-up-circle",
    "fileName": "arrow-up-circle.svg"
  },
  {
    "name": "arrow-up-left",
    "fileName": "arrow-up-left.svg"
  },
  {
    "name": "arrow-up-right",
    "fileName": "arrow-up-right.svg"
  },
  {
    "name": "arrow-up",
    "fileName": "arrow-up.svg"
  },
  {
    "name": "at-sign",
    "fileName": "at-sign.svg"
  },
  {
    "name": "award",
    "fileName": "award.svg"
  },
  {
    "name": "bar-chart-2",
    "fileName": "bar-chart-2.svg"
  },
  {
    "name": "bar-chart",
    "fileName": "bar-chart.svg"
  },
  {
    "name": "battery-charging",
    "fileName": "battery-charging.svg"
  },
  {
    "name": "battery",
    "fileName": "battery.svg"
  },
  {
    "name": "bell-off",
    "fileName": "bell-off.svg"
  },
  {
    "name": "bell",
    "fileName": "bell.svg"
  },
  {
    "name": "blogi",
    "fileName": "blogi.svg"
  },
  {
    "name": "bluetooth",
    "fileName": "bluetooth.svg"
  },
  {
    "name": "bold",
    "fileName": "bold.svg"
  },
  {
    "name": "book-marked",
    "fileName": "book-marked.svg"
  },
  {
    "name": "book-open",
    "fileName": "book-open.svg"
  },
  {
    "name": "book-user",
    "fileName": "book-user.svg"
  },
  {
    "name": "book",
    "fileName": "book.svg"
  },
  {
    "name": "bookmark",
    "fileName": "bookmark.svg"
  },
  {
    "name": "box",
    "fileName": "box.svg"
  },
  {
    "name": "briefcase",
    "fileName": "briefcase.svg"
  },
  {
    "name": "calendar-alternate",
    "fileName": "calendar-alternate.svg"
  },
  {
    "name": "calendar-clock",
    "fileName": "calendar-clock.svg"
  },
  {
    "name": "calendar-event",
    "fileName": "calendar-event.svg"
  },
  {
    "name": "calendar",
    "fileName": "calendar.svg"
  },
  {
    "name": "camera-off",
    "fileName": "camera-off.svg"
  },
  {
    "name": "camera",
    "fileName": "camera.svg"
  },
  {
    "name": "cast",
    "fileName": "cast.svg"
  },
  {
    "name": "chart-column",
    "fileName": "chart-column.svg"
  },
  {
    "name": "check-circle-alternate",
    "fileName": "check-circle-alternate.svg"
  },
  {
    "name": "check-circle",
    "fileName": "check-circle.svg"
  },
  {
    "name": "check-square",
    "fileName": "check-square.svg"
  },
  {
    "name": "check",
    "fileName": "check.svg"
  },
  {
    "name": "chevron-down",
    "fileName": "chevron-down.svg"
  },
  {
    "name": "chevron-left",
    "fileName": "chevron-left.svg"
  },
  {
    "name": "chevron-right",
    "fileName": "chevron-right.svg"
  },
  {
    "name": "chevron-up",
    "fileName": "chevron-up.svg"
  },
  {
    "name": "chevrons-down",
    "fileName": "chevrons-down.svg"
  },
  {
    "name": "chevrons-left",
    "fileName": "chevrons-left.svg"
  },
  {
    "name": "chevrons-right",
    "fileName": "chevrons-right.svg"
  },
  {
    "name": "chevrons-up",
    "fileName": "chevrons-up.svg"
  },
  {
    "name": "chrome",
    "fileName": "chrome.svg"
  },
  {
    "name": "circle",
    "fileName": "circle.svg"
  },
  {
    "name": "class",
    "fileName": "class.svg"
  },
  {
    "name": "clipboard",
    "fileName": "clipboard.svg"
  },
  {
    "name": "clock",
    "fileName": "clock.svg"
  },
  {
    "name": "cloud-drizzle",
    "fileName": "cloud-drizzle.svg"
  },
  {
    "name": "cloud-lightning",
    "fileName": "cloud-lightning.svg"
  },
  {
    "name": "cloud-off",
    "fileName": "cloud-off.svg"
  },
  {
    "name": "cloud-rain",
    "fileName": "cloud-rain.svg"
  },
  {
    "name": "cloud-snow",
    "fileName": "cloud-snow.svg"
  },
  {
    "name": "cloud",
    "fileName": "cloud.svg"
  },
  {
    "name": "code",
    "fileName": "code.svg"
  },
  {
    "name": "codepen",
    "fileName": "codepen.svg"
  },
  {
    "name": "codesandbox",
    "fileName": "codesandbox.svg"
  },
  {
    "name": "coffee",
    "fileName": "coffee.svg"
  },
  {
    "name": "columns",
    "fileName": "columns.svg"
  },
  {
    "name": "command",
    "fileName": "command.svg"
  },
  {
    "name": "compare",
    "fileName": "compare.svg"
  },
  {
    "name": "compass",
    "fileName": "compass.svg"
  },
  {
    "name": "copy",
    "fileName": "copy.svg"
  },
  {
    "name": "corner-down-left",
    "fileName": "corner-down-left.svg"
  },
  {
    "name": "corner-down-right",
    "fileName": "corner-down-right.svg"
  },
  {
    "name": "corner-left-down",
    "fileName": "corner-left-down.svg"
  },
  {
    "name": "corner-left-up",
    "fileName": "corner-left-up.svg"
  },
  {
    "name": "corner-right-down",
    "fileName": "corner-right-down.svg"
  },
  {
    "name": "corner-right-up",
    "fileName": "corner-right-up.svg"
  },
  {
    "name": "corner-up-left",
    "fileName": "corner-up-left.svg"
  },
  {
    "name": "corner-up-right",
    "fileName": "corner-up-right.svg"
  },
  {
    "name": "cpu",
    "fileName": "cpu.svg"
  },
  {
    "name": "credit-card",
    "fileName": "credit-card.svg"
  },
  {
    "name": "crop",
    "fileName": "crop.svg"
  },
  {
    "name": "crosshair",
    "fileName": "crosshair.svg"
  },
  {
    "name": "database",
    "fileName": "database.svg"
  },
  {
    "name": "delete",
    "fileName": "delete.svg"
  },
  {
    "name": "disc",
    "fileName": "disc.svg"
  },
  {
    "name": "dollar-sign",
    "fileName": "dollar-sign.svg"
  },
  {
    "name": "dot",
    "fileName": "dot.svg"
  },
  {
    "name": "download-cloud",
    "fileName": "download-cloud.svg"
  },
  {
    "name": "download",
    "fileName": "download.svg"
  },
  {
    "name": "droplet",
    "fileName": "droplet.svg"
  },
  {
    "name": "edit-2",
    "fileName": "edit-2.svg"
  },
  {
    "name": "edit-3",
    "fileName": "edit-3.svg"
  },
  {
    "name": "edit",
    "fileName": "edit.svg"
  },
  {
    "name": "exclamation",
    "fileName": "exclamation.svg"
  },
  {
    "name": "external-link",
    "fileName": "external-link.svg"
  },
  {
    "name": "eye-off",
    "fileName": "eye-off.svg"
  },
  {
    "name": "eye",
    "fileName": "eye.svg"
  },
  {
    "name": "facebook",
    "fileName": "facebook.svg"
  },
  {
    "name": "fast-forward",
    "fileName": "fast-forward.svg"
  },
  {
    "name": "feather",
    "fileName": "feather.svg"
  },
  {
    "name": "figma",
    "fileName": "figma.svg"
  },
  {
    "name": "file-badge",
    "fileName": "file-badge.svg"
  },
  {
    "name": "file-description",
    "fileName": "file-description.svg"
  },
  {
    "name": "file-input",
    "fileName": "file-input.svg"
  },
  {
    "name": "file-line",
    "fileName": "file-line.svg"
  },
  {
    "name": "file-minus",
    "fileName": "file-minus.svg"
  },
  {
    "name": "file-play",
    "fileName": "file-play.svg"
  },
  {
    "name": "file-plus",
    "fileName": "file-plus.svg"
  },
  {
    "name": "file-search",
    "fileName": "file-search.svg"
  },
  {
    "name": "file-text",
    "fileName": "file-text.svg"
  },
  {
    "name": "file",
    "fileName": "file.svg"
  },
  {
    "name": "film",
    "fileName": "film.svg"
  },
  {
    "name": "filter-x",
    "fileName": "filter-x.svg"
  },
  {
    "name": "filter",
    "fileName": "filter.svg"
  },
  {
    "name": "flag",
    "fileName": "flag.svg"
  },
  {
    "name": "folder-minus",
    "fileName": "folder-minus.svg"
  },
  {
    "name": "folder-plus",
    "fileName": "folder-plus.svg"
  },
  {
    "name": "folder",
    "fileName": "folder.svg"
  },
  {
    "name": "format-quote-outlined",
    "fileName": "format-quote-outlined.svg"
  },
  {
    "name": "format-quote",
    "fileName": "format-quote.svg"
  },
  {
    "name": "framer",
    "fileName": "framer.svg"
  },
  {
    "name": "frown",
    "fileName": "frown.svg"
  },
  {
    "name": "gem",
    "fileName": "gem.svg"
  },
  {
    "name": "gift",
    "fileName": "gift.svg"
  },
  {
    "name": "git-branch",
    "fileName": "git-branch.svg"
  },
  {
    "name": "git-commit",
    "fileName": "git-commit.svg"
  },
  {
    "name": "git-merge",
    "fileName": "git-merge.svg"
  },
  {
    "name": "git-pull-request",
    "fileName": "git-pull-request.svg"
  },
  {
    "name": "github",
    "fileName": "github.svg"
  },
  {
    "name": "gitlab",
    "fileName": "gitlab.svg"
  },
  {
    "name": "globe",
    "fileName": "globe.svg"
  },
  {
    "name": "graduation-cap-filled",
    "fileName": "graduation-cap-filled.svg"
  },
  {
    "name": "graduation-cap",
    "fileName": "graduation-cap.svg"
  },
  {
    "name": "graph-line",
    "fileName": "graph-line.svg"
  },
  {
    "name": "grid",
    "fileName": "grid.svg"
  },
  {
    "name": "hard-drive",
    "fileName": "hard-drive.svg"
  },
  {
    "name": "hash",
    "fileName": "hash.svg"
  },
  {
    "name": "header-search",
    "fileName": "header-search.svg"
  },
  {
    "name": "headphones",
    "fileName": "headphones.svg"
  },
  {
    "name": "heart",
    "fileName": "heart.svg"
  },
  {
    "name": "help-circle",
    "fileName": "help-circle.svg"
  },
  {
    "name": "hexagon",
    "fileName": "hexagon.svg"
  },
  {
    "name": "home",
    "fileName": "home.svg"
  },
  {
    "name": "image",
    "fileName": "image.svg"
  },
  {
    "name": "inbox",
    "fileName": "inbox.svg"
  },
  {
    "name": "info",
    "fileName": "info.svg"
  },
  {
    "name": "instagram",
    "fileName": "instagram.svg"
  },
  {
    "name": "italic",
    "fileName": "italic.svg"
  },
  {
    "name": "key",
    "fileName": "key.svg"
  },
  {
    "name": "language",
    "fileName": "language.svg"
  },
  {
    "name": "layers",
    "fileName": "layers.svg"
  },
  {
    "name": "layout",
    "fileName": "layout.svg"
  },
  {
    "name": "library-alt",
    "fileName": "library-alt.svg"
  },
  {
    "name": "library",
    "fileName": "library.svg"
  },
  {
    "name": "life-buoy",
    "fileName": "life-buoy.svg"
  },
  {
    "name": "lightbulb",
    "fileName": "lightbulb.svg"
  },
  {
    "name": "link-2",
    "fileName": "link-2.svg"
  },
  {
    "name": "link",
    "fileName": "link.svg"
  },
  {
    "name": "linkedin",
    "fileName": "linkedin.svg"
  },
  {
    "name": "list",
    "fileName": "list.svg"
  },
  {
    "name": "loader",
    "fileName": "loader.svg"
  },
  {
    "name": "local-library",
    "fileName": "local-library.svg"
  },
  {
    "name": "lock",
    "fileName": "lock.svg"
  },
  {
    "name": "log-in",
    "fileName": "log-in.svg"
  },
  {
    "name": "log-out",
    "fileName": "log-out.svg"
  },
  {
    "name": "mail",
    "fileName": "mail.svg"
  },
  {
    "name": "map-pin-alt",
    "fileName": "map-pin-alt.svg"
  },
  {
    "name": "map-pin",
    "fileName": "map-pin.svg"
  },
  {
    "name": "map",
    "fileName": "map.svg"
  },
  {
    "name": "maximize-2",
    "fileName": "maximize-2.svg"
  },
  {
    "name": "maximize",
    "fileName": "maximize.svg"
  },
  {
    "name": "meh",
    "fileName": "meh.svg"
  },
  {
    "name": "menu",
    "fileName": "menu.svg"
  },
  {
    "name": "message-circle",
    "fileName": "message-circle.svg"
  },
  {
    "name": "message-square",
    "fileName": "message-square.svg"
  },
  {
    "name": "mic-off",
    "fileName": "mic-off.svg"
  },
  {
    "name": "mic",
    "fileName": "mic.svg"
  },
  {
    "name": "minimize-2",
    "fileName": "minimize-2.svg"
  },
  {
    "name": "minimize",
    "fileName": "minimize.svg"
  },
  {
    "name": "minus-circle",
    "fileName": "minus-circle.svg"
  },
  {
    "name": "minus-square",
    "fileName": "minus-square.svg"
  },
  {
    "name": "minus",
    "fileName": "minus.svg"
  },
  {
    "name": "monitor",
    "fileName": "monitor.svg"
  },
  {
    "name": "moon",
    "fileName": "moon.svg"
  },
  {
    "name": "more-horizontal",
    "fileName": "more-horizontal.svg"
  },
  {
    "name": "more-vertical",
    "fileName": "more-vertical.svg"
  },
  {
    "name": "mouse-pointer",
    "fileName": "mouse-pointer.svg"
  },
  {
    "name": "move",
    "fileName": "move.svg"
  },
  {
    "name": "music",
    "fileName": "music.svg"
  },
  {
    "name": "navigation-2",
    "fileName": "navigation-2.svg"
  },
  {
    "name": "navigation",
    "fileName": "navigation.svg"
  },
  {
    "name": "newspaper",
    "fileName": "newspaper.svg"
  },
  {
    "name": "octagon",
    "fileName": "octagon.svg"
  },
  {
    "name": "package",
    "fileName": "package.svg"
  },
  {
    "name": "paperclip",
    "fileName": "paperclip.svg"
  },
  {
    "name": "pause-circle",
    "fileName": "pause-circle.svg"
  },
  {
    "name": "pause",
    "fileName": "pause.svg"
  },
  {
    "name": "pen-tool",
    "fileName": "pen-tool.svg"
  },
  {
    "name": "people-gray",
    "fileName": "people-gray.svg"
  },
  {
    "name": "people-green",
    "fileName": "people-green.svg"
  },
  {
    "name": "people",
    "fileName": "people.svg"
  },
  {
    "name": "percent",
    "fileName": "percent.svg"
  },
  {
    "name": "phone-call",
    "fileName": "phone-call.svg"
  },
  {
    "name": "phone-forwarded",
    "fileName": "phone-forwarded.svg"
  },
  {
    "name": "phone-incoming",
    "fileName": "phone-incoming.svg"
  },
  {
    "name": "phone-missed",
    "fileName": "phone-missed.svg"
  },
  {
    "name": "phone-off",
    "fileName": "phone-off.svg"
  },
  {
    "name": "phone-outgoing",
    "fileName": "phone-outgoing.svg"
  },
  {
    "name": "phone",
    "fileName": "phone.svg"
  },
  {
    "name": "pie-chart",
    "fileName": "pie-chart.svg"
  },
  {
    "name": "play-circle",
    "fileName": "play-circle.svg"
  },
  {
    "name": "play",
    "fileName": "play.svg"
  },
  {
    "name": "plus-circle",
    "fileName": "plus-circle.svg"
  },
  {
    "name": "plus-square",
    "fileName": "plus-square.svg"
  },
  {
    "name": "plus-thick",
    "fileName": "plus-thick.svg"
  },
  {
    "name": "plus",
    "fileName": "plus.svg"
  },
  {
    "name": "pocket",
    "fileName": "pocket.svg"
  },
  {
    "name": "podcast",
    "fileName": "podcast.svg"
  },
  {
    "name": "podcast_alt",
    "fileName": "podcast_alt.svg"
  },
  {
    "name": "power",
    "fileName": "power.svg"
  },
  {
    "name": "printer",
    "fileName": "printer.svg"
  },
  {
    "name": "radio",
    "fileName": "radio.svg"
  },
  {
    "name": "refresh-ccw",
    "fileName": "refresh-ccw.svg"
  },
  {
    "name": "refresh-cw",
    "fileName": "refresh-cw.svg"
  },
  {
    "name": "repeat",
    "fileName": "repeat.svg"
  },
  {
    "name": "report",
    "fileName": "report.svg"
  },
  {
    "name": "rewind",
    "fileName": "rewind.svg"
  },
  {
    "name": "rotate-ccw",
    "fileName": "rotate-ccw.svg"
  },
  {
    "name": "rotate-cw",
    "fileName": "rotate-cw.svg"
  },
  {
    "name": "rss",
    "fileName": "rss.svg"
  },
  {
    "name": "save",
    "fileName": "save.svg"
  },
  {
    "name": "school",
    "fileName": "school.svg"
  },
  {
    "name": "scissors",
    "fileName": "scissors.svg"
  },
  {
    "name": "scroll",
    "fileName": "scroll.svg"
  },
  {
    "name": "search",
    "fileName": "search.svg"
  },
  {
    "name": "send",
    "fileName": "send.svg"
  },
  {
    "name": "server",
    "fileName": "server.svg"
  },
  {
    "name": "settings",
    "fileName": "settings.svg"
  },
  {
    "name": "share-2",
    "fileName": "share-2.svg"
  },
  {
    "name": "share",
    "fileName": "share.svg"
  },
  {
    "name": "shield-off",
    "fileName": "shield-off.svg"
  },
  {
    "name": "shield",
    "fileName": "shield.svg"
  },
  {
    "name": "shopping-bag",
    "fileName": "shopping-bag.svg"
  },
  {
    "name": "shopping-cart",
    "fileName": "shopping-cart.svg"
  },
  {
    "name": "shuffle",
    "fileName": "shuffle.svg"
  },
  {
    "name": "sidebar",
    "fileName": "sidebar.svg"
  },
  {
    "name": "skip-back",
    "fileName": "skip-back.svg"
  },
  {
    "name": "skip-forward",
    "fileName": "skip-forward.svg"
  },
  {
    "name": "slack",
    "fileName": "slack.svg"
  },
  {
    "name": "slash",
    "fileName": "slash.svg"
  },
  {
    "name": "sliders",
    "fileName": "sliders.svg"
  },
  {
    "name": "smartphone",
    "fileName": "smartphone.svg"
  },
  {
    "name": "smile",
    "fileName": "smile.svg"
  },
  {
    "name": "speaker",
    "fileName": "speaker.svg"
  },
  {
    "name": "square",
    "fileName": "square.svg"
  },
  {
    "name": "star-filled",
    "fileName": "star-filled.svg"
  },
  {
    "name": "star",
    "fileName": "star.svg"
  },
  {
    "name": "statistics",
    "fileName": "statistics.svg"
  },
  {
    "name": "stop-circle",
    "fileName": "stop-circle.svg"
  },
  {
    "name": "studies",
    "fileName": "studies.svg"
  },
  {
    "name": "sun",
    "fileName": "sun.svg"
  },
  {
    "name": "sunrise",
    "fileName": "sunrise.svg"
  },
  {
    "name": "sunset",
    "fileName": "sunset.svg"
  },
  {
    "name": "tablet",
    "fileName": "tablet.svg"
  },
  {
    "name": "tag",
    "fileName": "tag.svg"
  },
  {
    "name": "target",
    "fileName": "target.svg"
  },
  {
    "name": "terminal",
    "fileName": "terminal.svg"
  },
  {
    "name": "thermometer",
    "fileName": "thermometer.svg"
  },
  {
    "name": "thumbs-down",
    "fileName": "thumbs-down.svg"
  },
  {
    "name": "thumbs-up",
    "fileName": "thumbs-up.svg"
  },
  {
    "name": "toggle-left",
    "fileName": "toggle-left.svg"
  },
  {
    "name": "toggle-right",
    "fileName": "toggle-right.svg"
  },
  {
    "name": "trash-2",
    "fileName": "trash-2.svg"
  },
  {
    "name": "trash",
    "fileName": "trash.svg"
  },
  {
    "name": "trello",
    "fileName": "trello.svg"
  },
  {
    "name": "trending-down",
    "fileName": "trending-down.svg"
  },
  {
    "name": "trending-up",
    "fileName": "trending-up.svg"
  },
  {
    "name": "triangle",
    "fileName": "triangle.svg"
  },
  {
    "name": "truck",
    "fileName": "truck.svg"
  },
  {
    "name": "tv",
    "fileName": "tv.svg"
  },
  {
    "name": "twitter",
    "fileName": "twitter.svg"
  },
  {
    "name": "type",
    "fileName": "type.svg"
  },
  {
    "name": "umbrella",
    "fileName": "umbrella.svg"
  },
  {
    "name": "underline",
    "fileName": "underline.svg"
  },
  {
    "name": "unlock",
    "fileName": "unlock.svg"
  },
  {
    "name": "upload-cloud",
    "fileName": "upload-cloud.svg"
  },
  {
    "name": "upload",
    "fileName": "upload.svg"
  },
  {
    "name": "user-check",
    "fileName": "user-check.svg"
  },
  {
    "name": "user-filled",
    "fileName": "user-filled.svg"
  },
  {
    "name": "user-minus",
    "fileName": "user-minus.svg"
  },
  {
    "name": "user-plus",
    "fileName": "user-plus.svg"
  },
  {
    "name": "user-round-search",
    "fileName": "user-round-search.svg"
  },
  {
    "name": "user-x",
    "fileName": "user-x.svg"
  },
  {
    "name": "user",
    "fileName": "user.svg"
  },
  {
    "name": "users",
    "fileName": "users.svg"
  },
  {
    "name": "video-blue",
    "fileName": "video-blue.svg"
  },
  {
    "name": "video-off",
    "fileName": "video-off.svg"
  },
  {
    "name": "video",
    "fileName": "video.svg"
  },
  {
    "name": "voicemail",
    "fileName": "voicemail.svg"
  },
  {
    "name": "volume-1",
    "fileName": "volume-1.svg"
  },
  {
    "name": "volume-2",
    "fileName": "volume-2.svg"
  },
  {
    "name": "volume-x",
    "fileName": "volume-x.svg"
  },
  {
    "name": "volume",
    "fileName": "volume.svg"
  },
  {
    "name": "watch",
    "fileName": "watch.svg"
  },
  {
    "name": "wifi-off",
    "fileName": "wifi-off.svg"
  },
  {
    "name": "wifi",
    "fileName": "wifi.svg"
  },
  {
    "name": "wind",
    "fileName": "wind.svg"
  },
  {
    "name": "x-circle",
    "fileName": "x-circle.svg"
  },
  {
    "name": "x-octagon",
    "fileName": "x-octagon.svg"
  },
  {
    "name": "x-square",
    "fileName": "x-square.svg"
  },
  {
    "name": "x",
    "fileName": "x.svg"
  },
  {
    "name": "youtube-play",
    "fileName": "youtube-play.svg"
  },
  {
    "name": "youtube",
    "fileName": "youtube.svg"
  },
  {
    "name": "zap-off",
    "fileName": "zap-off.svg"
  },
  {
    "name": "zap",
    "fileName": "zap.svg"
  },
  {
    "name": "zoom-in",
    "fileName": "zoom-in.svg"
  },
  {
    "name": "zoom-out",
    "fileName": "zoom-out.svg"
  }
];