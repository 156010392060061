import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { TranslateService } from '@app/_modules/translate/translate.service';
import { AlertsService, SettingsService, AuthService } from '@app/_services';
import { distinctUntilChanged, map, startWith, take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'certificate-document-check',
  templateUrl: './certificate-document-check.component.html',
  styleUrls: ['./certificate-document-check.component.scss'],
})
export class CertificateDocumentCheckComponent implements OnInit {

  public model: UntypedFormGroup = this.formBuilder.group({
    id_code: ['', [this.validateIdCode]],
    document_id: ['', Validators.required],
    date: [null, [this.validateDate]]
  });
  documentError = toSignal(this.mapErrors(this.model.controls.document_id));
  idError = toSignal(this.mapErrors(this.model.controls.id_code));
  dateError = toSignal(this.mapErrors(this.model.controls.date));

  public dataFetched = false;
  public loading = false;
  public documentData: any = {};
  public tableOverflown = false;

  public initialized = false;
  public loginStatus = false;

  public path = this.location.path();
  public minDate = new Date('1900-01-01');
  @ViewChild('scrollTarget') public scrollTarget;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertsService: AlertsService,
    private translate: TranslateService,
    private settings: SettingsService,
    private http: HttpClient,
    private location: Location,
    public auth: AuthService,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
  ) {}
  public ngOnInit() {
    this.alertsService.clear('general');
    this.subscribeToAuth();
    this.model.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)).subscribe(values => {
      // Also disabling with attribute due to poor handling of disabled state, will likely be unnecessary in newer inputs
      if (values.id_code) {
        this.model.controls.date.disable({onlySelf: true});
      } else {
        this.model.controls.date.enable({onlySelf: true});
      }
      if (values.date) {
        this.model.controls.id_code.disable({onlySelf: true});
      } else {
        this.model.controls.id_code.enable({onlySelf: true});
      }
    })
  }

  public submit() {
    if (!this.validateForm()) {
      return;
    }
    this.loading = true;
    let urlPartial = '';
    if (this.model.value.date) {
      urlPartial = `-/${this.model.value.date}`;
    } else {
      urlPartial = `${this.model.value.id_code}/-`;
    }

    const documentId = `${this.model.value.document_id}`.replace(/\s/g, '').replace(/\//g, "'");
    this.http
      .get(
        `${
          this.settings.ehisUrl
        }/avaandmed/lopudokumendid/${urlPartial}/${encodeURIComponent(documentId)}/JSON`,
      )
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          if (res.body.vastuseKood === 0) {
            const string = this.translate
              .get('documentCheck.found_result')
              .replace(
                '%LIIK%',
                res.body.lopudokumendid.lopudokument[0].onHinneteleht === '1'
                  ? res.body.lopudokumendid.lopudokument[0]
                    .hinneteleheNimetus.replace(/&apos;/g, "'")
                  : res.body.lopudokumendid.lopudokument[0]
                      .pohiDokumendiLiigiNimetus.replace(/&apos;/g, "'"),
              )
              .replace(
                '%VASTAVUS%',
                res.body.lopudokumendid.lopudokument[0].vastavuseNimetus.replace(/&apos;/g, "'"),
              );
            this.alertsService.success(string, 'documentCheck', false);
          }
          if (res.body.vastuseKood === 1) {
            this.alertsService.warning(
              res.body.koodiSelgitus,
              'documentCheck',
              false,
            );
          }
          if (res.body.vastuseKood === 2) {
            this.alertsService.error(
              res.body.koodiSelgitus,
              'documentCheck',
              false,
            );
          }
          window.setTimeout(() => {
            this.scrollTarget.nativeElement.scrollIntoView({
              behavior: 'smooth',
            });
          },                1000);
        },
        error: (error) => {
          this.alertsService.error(
            this.translate.get('errors.request'),
            'documentCheck',
            false,
          );
          this.loading = false;
          window.setTimeout(() => {
            this.scrollTarget.nativeElement.scrollIntoView({
              behavior: 'smooth',
            });
            this.cdr.markForCheck();
          },                1000);
        },
      });
  }

  private subscribeToAuth() {
    this.auth.isAuthenticated.pipe(take(1)).subscribe((val) => {
      this.loginStatus = val;
    });
  }

  private validateIdCode(control: AbstractControl) {
    if (
      (!control.value && !control?.parent?.get('date')?.value) ||
      !`${control.value}`.match(
        /([1-6]\d{2}[0,1]\d[0-3]\d{5})/g,
      )
    ) {
      return {customIdMissing: 'document_check.id_code_invalid'};
    }
    return null;
  }

  private validateDate(control: AbstractControl) {
    if (
      (!control.value && !control?.parent?.get('id_code')?.value) ||
      !`${control.value}`.match(
        /((\d{2}\.)(\d{2}\.)\d{4})/g,
      )
    ) {
      return {customDateMissing: 'document_check.date_invalid'};
    }
    return null;
  }

  private mapErrors(control: AbstractControl<any, any>) {
    return control.statusChanges.pipe(
			startWith(control.status),
			map(() => {
				if (Object.keys(control.errors ?? {})?.length === 0 || !control.dirty) {
					return null;
				}
				const firstError = Object.entries(control.errors ?? {})?.[0];
				if (firstError?.[0]?.includes('custom')) {
					return this.translate.get(`${firstError?.[1]}`);
				} else {
					return this.translate.get(`errors.field_${firstError?.[0]}`);
				}
			})
		);
  }
  private validateForm() {
    if (this.model.valid) {
      return true;
    }
    let valid = true;
    // Either id code or date must be filled
    if (!(this.model.value.id_code || this.model.value.date)) {
      this.model.controls.id_code.markAsDirty();
      (<EventEmitter<any>> this.model.controls.id_code.statusChanges).emit('INVALID');
      this.model.controls.date.markAsDirty();
      (<EventEmitter<any>> this.model.controls.date.statusChanges).emit('INVALID');
      valid = false;
    }
    // Document id must be filled, if it was already touched the error will be shown but if user just tried submitting, trigger the error
    if (!this.model.value.document_id) {
      this.model.controls.document_id.markAsDirty();
      (<EventEmitter<any>> this.model.controls.document_id.statusChanges).emit('INVALID');
      valid = false;
    }
    return valid;
  }
}
