<skip-to-content/>
<div class="app-wrapper">
  <loader [message]="loaderMessage" *ngIf="(translationsLoaded$ | async) === false"></loader>
  <ng-container *ngIf="(translationsLoaded$ | async)">
    <alerts global id="cookie"></alerts>
    <!-- <sidemenu></sidemenu> -->
    <navigation></navigation>
    <div class="app-flex-main">
      <global-alert />
      <htm-header></htm-header>
      <div class="app-content" (click)="closeSidemenu($event)">
        <div tabindex="-1" id="content"></div>
        <alerts id="neuro-translation"></alerts>
        <router-outlet (activate)="onActivate()"></router-outlet>
        <htm-footer></htm-footer>
      </div>
      <!--/app-content-->
    </div><!--/app-flex-main-->

    <alerts global></alerts>

    <sessionExpiration></sessionExpiration>

    <digi-sign-overlay></digi-sign-overlay>
  </ng-container>
</div><!--/app-wrapper-->
