import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	inject,
	OnInit,
	viewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
	AuthService,
	BreakpointService,
	LanguageService,
	SettingsService,
	SidemenuService,
} from '@app/_services';
import { IMenuItem, IMenuItems } from './navigation.interface';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { CommonModule, Location } from '@angular/common';
import { map, of, switchMap } from 'rxjs';
import { DarkModeModule } from '../../modules/dark-mode/dark-mode.module';
import { NavigationItemsComponent } from './navigation-items/navigation-items.component';
import { ButtonComponent } from '../button/button.component';
import { TranslateModule } from '@app/_modules/translate';
import { IconComponent } from '../icon/icon.component';
import { LanguageSwitcherComponent } from '../language-switcher/language-switcher.component';

const noLinkUrls = ['#nolink', '#nocategory', '#category'];

@Component({
	selector: 'navigation',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		DarkModeModule,
		NavigationItemsComponent,
		ButtonComponent,
		TranslateModule,
		IconComponent,
		LanguageSwitcherComponent,
	],
	templateUrl: './navigation.component.html',
	styleUrl: './navigation.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent implements OnInit {
	public isVisible: boolean;
	public readerVisible = false;

	private initialSub = false;
	private focusBounce: ReturnType<typeof setTimeout>;
	private visibilityBounce: ReturnType<typeof setTimeout>;
	private closeBtn = viewChild<ButtonComponent>('mobileCloser');

	public service = inject(SidemenuService);
	public breakpoint = inject(BreakpointService);
	private settings = inject(SettingsService);
	private auth = inject(AuthService);
	private router = inject(Router);
	private cdr = inject(ChangeDetectorRef);
	private location = inject(Location);
	private destroyRef = inject(DestroyRef);
	protected languageService = inject(LanguageService);

	protected data = toSignal(this.service.$data);
	protected isLoading = this.service.isLoading;

	protected activeSecondLevel = toSignal(
		this.service.$data.pipe(
			switchMap((items) =>
				this.breakpoint.isMobile$.pipe(
					switchMap((isMobile) => {
						return of(
							isMobile?.matches
								? items
								: items.find((entry) => entry.below?.length && entry.expanded)
										?.below || [],
						);
					}),
				),
			),
		),
	);
	protected activeTheme = toSignal(this.service.themeSubscription);

	public closeNavigation(): void {
		this.service.close();
	}

	/**
	 * This function will either navigate to an URL or expand/hide a menu.
	 * Navigation takes precedence.
	 * @param item `IMenuItem` object
	 */
	public clickMenuItem(item: IMenuItem) {
		const path = decodeURI(this.location.path());
		const match = path.replace(/\?.*/, '') === decodeURI(item.relative);
		if (
			!match &&
			// Check if item url is missing or a hash variant that doesn't lead anywhere
			!noLinkUrls.some(
				(url) => !item?.relative || item?.relative?.includes(url),
			)
		) {
			this.router.navigateByUrl(item.relative);
			// If the page is not a homepage, close the menu on mobile view
			if (this.service.isMobileView && !item.firstLevel) {
				this.service.close();
			}
		} else {
			if (item.below?.length) {
				const data = this.data();
				this.closeAllLinks(data, item);
				this.service.setData(data);
			}
		}
	}

	private closeAllLinks(list: IMenuItems, currentItem?: IMenuItem): boolean {
		let containsCurrentItem = false;
		list.forEach((item) => {
			// Toggle the current item
			if (currentItem && item.key === currentItem.key) {
				item.expanded = !item.expanded;
				if (!item.expanded) {
					item.userClosed = true;
				} else if (item.userClosed) {
					item.userClosed = false;
				}

				containsCurrentItem = true;
				return;
			}

			// This is effectively a depth-first search, ensuring that we do not close
			// the entire tree when toggling a sub-menu.
			if (item.below?.length) {
				if (this.closeAllLinks(item.below, currentItem)) {
					containsCurrentItem = true;
					return;
				}
			}

			item.expanded = false;
		});

		return containsCurrentItem;
	}

	private subscribeToLanguage(): void {
		this.settings.activeLang$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe({
				next: () => {
					this.service.languageWasChanged.set(true);
					this.service.getData();
				},
				complete: () => this.service.languageWasChanged.set(false),
			});
	}

	private subscribeToRouter(): void {
		this.router.events
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					if (!event.url.endsWith('#content') && this.initialSub) {
						this.service.resetPageFocus();
					}
					this.service.makeActive();
					this.cdr.markForCheck();
				}
			});
	}

	private subscribeToAuth(): void {
		this.auth.isAuthenticated
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.service.getData();
			});
	}

	private subscribeToService(): void {
		this.service.isVisibleSubscription
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((value) => {
				this.isVisible = value;
				clearTimeout(this.visibilityBounce);
				if (!value && this.initialSub) {
					this.service.wasClosed.set(true);
				}
				if (value) {
					this.readerVisible = true;
					clearTimeout(this.focusBounce);
					if (this.initialSub && !this.service.initialAutoOpen()) {
						this.focusBounce = setTimeout(
							() => this.closeBtn()?.buttonRef.nativeElement.focus(),
							100,
						);
					}
				} else {
					this.visibilityBounce = setTimeout(() => {
						this.readerVisible = false;
						this.cdr.markForCheck();
					}, 200);
				}
				// Ignore the initial state
				this.initialSub = true;
				if (this.service.initialAutoOpen()) {
					this.service.initialAutoOpen.set(false);
				}
				this.cdr.markForCheck();
			});
	}

	public ngOnInit(): void {
		this.subscribeToAuth();
		this.subscribeToService();
		this.subscribeToRouter();
		this.subscribeToLanguage();
	}
}
