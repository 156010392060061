<ul
	class="navigation__items navigation__items--{{
		type()
	}} navigation__items--level-{{ nestingLevel() }}"
>
	@for (item of items(); track item.key) {
		<navigation-link
			[type]="type()"
			[item]="item"
			[expanded]="item.below?.length && item.expanded"
			[nestingLevel]="nestingLevel()"
			(onClickItem)="onClickItem.emit($event)"
		>
			@if (autoNesting() && item.below?.length && item.expanded) {
				<navigation-items
					[items]="item.below"
					[activeTheme]="activeTheme()"
					[nestingLevel]="nestingLevel() + 1"
					(onClickItem)="onClickItem.emit($event)"
				></navigation-items>
			}
		</navigation-link>
	}
</ul>
