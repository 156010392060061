import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	input,
	output,
} from '@angular/core';
import { NavigationLinkComponent } from '../navigation-link/navigation-link.component';
import { IMenuItem, IMenuItems } from '../navigation.interface';

@Component({
	selector: 'navigation-items',
	standalone: true,
	imports: [CommonModule, NavigationLinkComponent],
	templateUrl: './navigation-items.component.html',
	styleUrl: './navigation-items.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationItemsComponent {
	public items = input<IMenuItems>([]);
	public type = input<string>('item');
	public autoNesting = input<boolean>(true);
	public activeTheme = input<string>();
	public nestingLevel = input<number>(0);
	public onClickItem = output<IMenuItem>();
}
