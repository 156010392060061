@if (item(); as resolvedItem) {
	<li
		class="navigation__item navigation__item--{{ type() }}"
		[ngClass]="[
			active() ? 'active' : '',
			expanded() ? 'expanded' : '',
			resolvedItem?.below?.length ? 'nested' : '',
			resolvedItem?.theme
				? 'navigation__item--theme-' + resolvedItem.theme
				: '',
			'navigation__item--level-' + nestingLevel(),
		]"
	>
		@if (
			resolvedItem?.relative !== '/#nolink' &&
			resolvedItem?.relative !== '/#nocategory' &&
			resolvedItem?.relative !== '/#category'
		) {
			<a
				(click)="onClickItem.emit(resolvedItem); (false)"
				class="navigation__button navigation__button--{{ type() }} "
				href="{{ resolvedItem.relative }}"
				[attr.aria-expanded]="resolvedItem?.below?.length ? expanded() : null"
				[attr.aria-controls]="
					resolvedItem?.below?.length ? (resolvedItem.title | slugify) : null
				"
				[class.with-submenu]="resolvedItem?.below?.length"
				[attr.aria-haspopup]="resolvedItem?.below?.length !== 0"
				[attr.aria-current]="active()"
				[class.active]="active()"
				(mousedown)="animateRipple($event)"
				[attr.data-test-id]="
					'navigation-link-' + (resolvedItem?.title?.toLowerCase() | slugify)
				"
				[attr.data-slug-title]="resolvedItem?.sluggedTitle"
			>
				<span class="navigation__button-title">
					@if (resolvedItem.icon) {
						<span class="navigation__button-icon">
							<icon
								class="menu-icon xmedium"
								[glyph]="resolvedItem.icon"
								size="xmedium"
								[noFill]="true"
							></icon>
						</span>
					}

					<span class="navigation__button-text">{{ resolvedItem.title }}</span>

					@if (nestingLevel() > 0) {
						<icon
							*ngIf="resolvedItem?.below?.length"
							class="chevron expand xmedium"
							glyph="chevron-right"
							size="xmedium"
						></icon>
					}
				</span>
			</a>
		}

		@if (
			resolvedItem?.relative === '/#nolink' ||
			resolvedItem?.relative === '/#nocategory' ||
			resolvedItem?.relative === '/#category'
		) {
			<button
				(click)="onClickItem.emit(resolvedItem); (false)"
				class="navigation__button navigation__button--{{
					type()
				}} navigation__button--level-{{ nestingLevel() }}"
				[attr.aria-expanded]="resolvedItem?.below?.length ? expanded() : null"
				[attr.aria-controls]="
					resolvedItem?.below?.length ? (resolvedItem.title | slugify) : null
				"
				[class.with-submenu]="resolvedItem?.below?.length"
				[attr.aria-haspopup]="resolvedItem?.below?.length !== 0"
				[attr.aria-current]="active()"
				[class.active]="active()"
				(mousedown)="animateRipple($event)"
				[attr.data-test-id]="
					'navigation-link-' + (resolvedItem?.title?.toLowerCase() | slugify)
				"
				[attr.data-slug-title]="resolvedItem?.sluggedTitle"
			>
				<span class="navigation__button-title">
					@if (resolvedItem.icon) {
						<span class="navigation__button-icon">
							<icon
								class="menu-icon xmedium"
								[glyph]="resolvedItem.icon"
								size="xmedium"
								[noFill]="true"
							></icon>
						</span>
					}

					<span class="navigation__button-text">{{ resolvedItem.title }}</span>

					@if (nestingLevel() > 0) {
						<icon
							class="chevron xmedium"
							glyph="chevron-right"
							size="xmedium"
						></icon>
					}
				</span>
			</button>
		}

		<ng-content></ng-content>
	</li>
}
