@if (!isLoading()) {
	<aside class="navigation navigation-theme--{{ activeTheme() }}">
		@if (breakpoint.isDesktop()) {
			<div class="navigation__permabar">
				<nav class="navigation__permabar__nav">
					<navigation-items
						type="category"
						[items]="data()"
						[autoNesting]="false"
						[nestingLevel]="0"
						[activeTheme]="activeTheme()"
						(onClickItem)="clickMenuItem($event)"
					></navigation-items>
				</nav>

				<div class="navigation__permabar__buttons">
					<dark-mode-toggle variant="naviation"></dark-mode-toggle>
				</div>
			</div>
		}

		<div
			class="navigation__submenu{{ readerVisible ? ' reader-visible' : '' }}{{
				isVisible ? ' is-visible' : ''
			}}{{ activeSecondLevel()?.length ? ' has-content' : '' }}"
		>
			@if (breakpoint.isMobile()) {
				<div class="space">
					<button
						htm-button
						theme="icon"
						attr.aria-label="{{ 'button.close' | translate }} {{
							'frontpage.main_menu' | translate
						}}"
						(click)="closeNavigation()"
						#mobileCloser
					>
						<span class="button--label">
							<icon
								class="xmedium"
								glyph="x"
								size="xmedium"
								aria-hidden="true"
							></icon>
						</span>
					</button>
				</div>
			}

			@if (activeSecondLevel(); as items) {
				@if (items.length) {
					<div class="navigation__submenu__inner">
						<navigation-items
							[items]="items"
							[autoNesting]="true"
							[nestingLevel]="1"
							type="item"
							[activeTheme]="activeTheme()"
							(onClickItem)="clickMenuItem($event)"
						></navigation-items>
					</div>
				}
			}

			@if (breakpoint.isMobile()) {
				<div class="navigation__mobile-buttons">
					<language-switcher
						[languageData]="languageService.languageSwitcherData$ | async"
						(languageChangeEvent)="languageService.handleLanguageChange($event)"
						[isInSidemenu]="true"
					></language-switcher>

					<dark-mode-toggle variant="mobile"></dark-mode-toggle>
				</div>
			}
		</div>
	</aside>
}
